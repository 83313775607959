<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogTestSet"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Tạo Test Set</span
              >
              <span class="headline" v-else>Sửa Test Set</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogTestSet = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Name:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        dense
                        v-model="name_input"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Mô tả:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <Editor v-model="description_input"></Editor>
                    </v-col>
                  </v-row>
                  <template v-if="tags_action">
                    <template v-for="(tag, iTag) in tags_action">
                      <v-row class="mt-6" :key="iTag">
                        <v-col cols="2" class="text-right p-0 m-0"
                          ><label class="text-h6">{{ tag.name }}:</label></v-col
                        >
                        <v-col cols="8" class="p-0 m-0 ml-4">
                          <v-switch
                            class="mt-0 pt-0"
                            dense
                            v-model="tag.is_selected"
                            :label="tag.is_selected ? 'Yes' : 'No'"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </template>
                  </template>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Trạng thái:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-radio-group v-model="status_input" row class="mt-0">
                        <v-radio
                          label="Disable"
                          :value="status.DISABLED"
                        ></v-radio>
                        <v-radio
                          label="Active"
                          :value="status.ACTIVE"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Ảnh:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4" id="avatar-test-set">
                      <b-form-file
                        @change="onChangeAvatar"
                        placeholder="Chọn tệp tin hoặc cancel để xóa"
                        accept="image/*"
                      ></b-form-file>
                      <template v-if="avatar_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="avatar_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Product Category:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="product_category_id"
                        :items="product_category_tags"
                        item-text="name.vi"
                        item-value="id"
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0">
                      <label class="text-h6">Thị trường:</label>
                    </v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="market_ids_input"
                        :items="markets"
                        item-text="label"
                        item-value="value"
                        outlined dense multiple chips deletable-chips
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0">
                      <label class="text-h6">Platform:</label>
                    </v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="platform_ids_input"
                        :items="platforms"
                        item-text="label"
                        item-value="value"
                        outlined dense multiple chips deletable-chips
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0">
                      <label class="text-h6">Năm:</label>
                    </v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <date-picker
                          class="ml-2"
                          type="year"
                          v-model="year_input"
                          value-type="format"
                          format="YYYY"
                      />
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialogTestSet = false">Đóng</v-btn>

            <v-btn
                class="font-weight-bold"
                outlined
                color="blue darken-1"
                @click="btnAddOrEditTestSet(type_form === 'add' ? 1 : 2)"
            >{{type_form === 'add' ? 'Thêm mới' : 'Cập nhập'}}</v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Editor from "@/components/ckeditor5/Editor";
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "TestSetModal",
  components: {
    Editor,
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    model_input: {
      type: String,
      default: "",
    },
    test_set: {},
    product_category_tags: {
      type: Array,
      default: () => [],
    },
    tags_action: {
      type: Array,
      default: () => [],
    },
    markets: {
      type: Array,
      default: () => [],
    },
    platforms: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      is_call_api: false,
      name_input: null,
      description_input: null,
      avatar_data: null,
      avatar_file: null,
      status_input: 1,
      product_category_id: null,
      test_set_id: null,
      status: {
        DISABLED: 0,
        ACTIVE: 1,
      },
      has_virtual_room_input: 1,
      show_test_practice_input: 0,
      is_hot_input: false,
      is_predict_input: false,
      market_id_input: null,
      market_ids_input: [],
      platform_ids_input: [],
      year_input: null,
    };
  },
  computed: {
    dialogTestSet: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogTestSet(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.name_input = "";
        this.description_input = "";
        this.avatar_data = null;
        this.avatar_file = null;
        this.status_input = this.status.ACTIVE;
        this.product_category_id = null;
        // this.show_test_practice_input  = this.status.DISABLED;
        // this.has_virtual_room_input = 1;
        this.market_id_input = null;
        this.market_ids_input = [];
        this.year_input = null;
        this.setDefaultLabelAvatar();
      } else {
        let data = JSON.parse(JSON.stringify(this.test_set));
        this.test_set_id = data.id;
        this.name_input = data.name;
        this.description_input = data.description == null ? "" : data.description;
        this.avatar_data = data.avatar;
        this.avatar_file = null;
        this.status_input = parseInt(data.status);
        this.product_category_id = data.category_id;
        this.is_hot_input = data.hot === 1;
        this.is_predict_input = data.predict === 1;
        // this.has_virtual_room_input = data.has_virtual_room;
        // this.show_test_practice_input = data.show_test_practice;
        this.market_id_input = data.market_id ?? null;
        this.market_ids_input = data.market_ids ?? [];
        this.year_input = data.year ? data.year.toString() : null;
        this.platform_ids_input = this.$utils.isValueInvalid(data.platforms) ? [] : data.platforms.map(e => e.value);
        this.setDefaultLabelAvatar();
      }
    },
    validateData() {
      let flat = true;
      if (this.name_input === null || this.name_input === "") {
        this.errorMessage("Hãy nhập name !");
        flat = false;
      }
      if (this.status_input === null || this.status_input === "") {
        this.errorMessage("Hãy chọn trạng thái !");
        flat = false;
      }
      if (this.avatar_file != null) {
        if (
          !(
            this.getExtensionFile(this.avatar_file) === "jpeg" ||
            this.getExtensionFile(this.avatar_file) === "jpg" ||
            this.getExtensionFile(this.avatar_file) === "png"
          )
        ) {
          this.errorMessage("Không đúng định dạng ảnh !");
          flat = false;
        }
      }
      return flat;
    },
    btnAddOrEditTestSet(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }

      let tag_selected = this.tags_action.filter((e) => e.is_selected);

      let vm = this;
      let formData = new FormData();
      formData.append("name", this.name_input);
      formData.append("status", this.status_input);
      formData.append("model_input", this.model_input);
      formData.append("market_ids", this.market_ids_input.length ? this.market_ids_input : '');
      formData.append("platform_ids", this.platform_ids_input.length ? this.platform_ids_input : '');
      // formData.append('has_virtual_room', this.has_virtual_room_input);
      // formData.append('show_test_practice', this.show_test_practice_input);
      if (!(this.description_input === null || this.description_input === ""))
        formData.append("description", this.description_input);
      if (
        !(this.product_category_id === null || this.product_category_id === "")
      )
        formData.append("product_category_id", this.product_category_id);
      if (this.avatar_file != null) formData.append("avatar", this.avatar_file);
      // if(this.is_hot_input) formData.append('hot', 1);
      // if(this.is_predict_input) formData.append('predict', 1);
      if(this.market_id_input) formData.append('market_id', this.market_id_input);
      if(this.year_input) formData.append('year', this.year_input);
      if (tag_selected.length > 0) {
        let tag_ids = tag_selected.map((e) => e.id);
        formData.append("tag_ids", tag_ids);
      }
      this.is_call_api = true;

      if (type === 1) {
        ApiService.post("prep-app/test-set", formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.successMess("Thêm thành công !");
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogTestSet = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              if (error.response.data.message) {
                vm.errorMessage(error.response.data.message);
              }
              if (error.response.data.error.message) {
                let data_response_error =
                    error.response.data.error.message.split(".<br />");
                vm.showError(data_response_error);
              }
            }
          });
      } else {
        formData.append("_method", "PUT");
        ApiService.post("prep-app/test-set/" + vm.test_set_id, formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.successMess("Sửa thành công !");
              vm.$emit("resetData");
              vm.is_call_api = false;
              vm.dialogTestSet = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              if (error.response.data.message) {
                vm.errorMessage(error.response.data.message);
              }
              if (error.response.data.error.message) {
                let data_response_error =
                  error.response.data.error.message.split(".<br />");
                vm.showError(data_response_error);
              }
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.errorMessage(data_error[i] + " !!");
      }
    },
    errorMessage(msg = 'error') {
      this.$toasted.error(msg, {
        theme: "toasted-primary",
        position: "top-right",
        duration: 4000,
      });
    },
    successMess(mess = "Thành công !") {
      this.$toasted.success(mess, {
        theme: "toasted-primary",
        position: "top-right",
        duration: 4000,
      });
    },
    onChangeAvatar(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        vm.avatar_data = null;
        vm.avatar_file = null;
        return;
      }
      vm.avatar_file = file;

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.avatar_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    setDefaultLabelAvatar() {
      let ele = document.getElementById("avatar-test-set");
      if (ele) {
        ele.firstChild.children[1].firstChild.textContent =
          "Chọn tệp tin hoặc cancel để xóa";
      }
    },
    getExtensionFile(file) {
      let filename = file.name;
      return filename.split(".").pop().toLowerCase();
    },
  },
};
</script>

<style scoped></style>
